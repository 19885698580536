import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Box } from 'grommet'
import { HeaderThree } from 'secondstep-components'
import PlainProgramCard from 'components/dashboard/PlainProgramCard'

export const Content = styled.div`
  padding-left: 0.625rem;
  padding-right: 0.625rem;

  ${themeGet('breakpoints.mobile')} {
    padding-left: 0.375rem !important;
    padding-right: 0.375rem !important;
  }
`
export const Hairline = styled.div`
  height: 1px;
  border-radius: 0.5px;
  background-color: ${themeGet('colors.gray500')};
  margin-bottom: ${themeGet('spacing.turquoise20_1_25rem')};
  width: calc(100%-1.25rem);

  ${themeGet('breakpoints.mobile')} {
    margin-bottom: 1rem;
  }
`

export const ProgramCardsContainer = styled(Box).attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  margin-left: auto;
  margin-right: auto;
  margin-bottom: -1.25rem;

  [class*='IconBox'] {
    margin-top: 1.9375rem;
  }

  [class*='FormFieldStyled'] {
    margin-bottom: 0rem;
  }
`

export const SelaLoadingDiv = styled.div`
  padding-bottom: 0.9375rem;
`

export const SelaModuleWrapper = styled.div.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
width: 100%;
margin-bottom: 1.25rem !important;

& :first-of-type {
  margin-top: 0;
}
& :last-of-type {
  margin-bottom: 0.625rem;
}

${themeGet('breakpoints.mobile')} {
  margin-bottom: ${themeGet('spacing.blue16_1rem')}; !important;
}

${themeGet('breakpoints.tablet')} {
  max-width: 42.5rem;
}
`

export const SelaTilesContainer = styled(Box)`
  display: grid;
  grid-template-columns: 24.125rem 24.125rem;
  justify-content: start;
  width: 100%;
  margin-top: 0.375rem;
  margin-bottom: ${themeGet('spacing.turquoise20_1_25rem')};
  grid-column-gap: ${themeGet('spacing.turquoise20_1_25rem')};
  grid-row-gap: ${themeGet('spacing.turquoise20_1_25rem')};

  ${HeaderThree} {
    width: 100%;
  }

  ${themeGet('breakpoints.xlarge')} {
    min-width: 39.375rem;
  }

  @media (max-width: 1260px) {
    grid-template-columns: minmax(auto, 24.125rem) minmax(auto, 24.125rem);
  }

  @media (max-width: 1020px) {
    grid-template-columns: 100%;
    grid-row-gap: ${themeGet('spacing.blue16_1rem')};
  }

  @media (max-width: 475px) {
    margin-top: 0.125rem;
    margin-bottom: ${themeGet('spacing.blue16_1rem')};
  }
`

export const StyledPlainProgramCard = styled(PlainProgramCard)`
  display: none;

  ${themeGet('breakpoints.tablet')} {
    display: flex;
  }
`

export const TilesContainer = styled(Box)`
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1.25rem;
  padding: 0.625rem 0.625rem 1.25rem 0.625rem;
  min-width: 18.4375rem;

  ${HeaderThree} {
    width: 100%;
  }

  ${themeGet('breakpoints.xlarge')} {
    min-width: 39.375rem;
  }
  ${themeGet('breakpoints.tablet')} {
    min-width: 0;
  }
`

export const MyKitsGradeTilesContainer = styled(TilesContainer)`
  gap: 0;
  button {
    margin: 0.6125rem;
  }
`

export const OstTeachLinksWrapper = styled.div`
  button {
    background-color: ${themeGet('colors.buttons.primaryBackground')};
    &:hover {
      background-color: ${themeGet('colors.buttons.primaryBackgroundHover')};
    }
  }
  .title {
    font-family: ${themeGet('font.molde.semiBold')};
    color: white;
    letter-spacing: 0;
    line-height: 18px;
    font-weight: 400;
  }
  .label {
    color: white;
  }
`

export const OstStaticLinksWrapper = styled.div`
  border-radius: 0.625rem;
  .title {
    font-family: ${themeGet('font.molde.semiBold')};
    font-weight: 400;
    color: ${themeGet('colors.buttons.header.level.5')};
    letter-spacing: 0;
    line-height: 18px;
  }
  svg {
    path {
      fill: ${themeGet('colors.labels.primaryBackground')};
    }
  }
  &:hover {
    .label {
      color: ${themeGet('colors.labels.primaryHoverBackground')};
    }
    svg {
      path {
        fill: ${themeGet('colors.labels.primaryHoverBackground')};
      }
    }
  }
  .label {
    color: ${themeGet('colors.labels.primaryBackground')};
  }
`
