/* istanbul ignore file */
// we don't test SVGs without arguments
import React from 'react'

const HeartIcon = () => (
  <svg
    aria-hidden="true"
    height="15px"
    role="presentation"
    version="1.1"
    viewBox="0 0 17 15"
    width="17px"
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <path
        d="M8.81399683,13.451606 C22.5997694,6.1124898 13.0781715,-3.42381906 8.68016708,3.52361243 C8.64433,3.59093666 8.57530093,3.63282012 8.50031547,3.63282012 C8.42533001,3.63282012 8.35630094,3.59093954 8.32057395,3.52361243 C5.55543054,-1.16795736 0.107121914,1.24081423 1.12507003,6.17906755 C1.64875154,9.32522653 6.82117568,12.7264561 8.21880771,13.4518942 C8.40423178,13.5552972 8.62864333,13.5552972 8.81399683,13.451606 Z"
        fill="#E97E6E"
        fillRule="nonzero"
        id="Path"
      />
    </g>
  </svg>
)

export default HeartIcon
