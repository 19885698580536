import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { TextLink } from 'secondstep-components'

export const NavTabSet = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: inherit;
  width: 100%;
  box-sizing: border-box;
  z-index: 2;
  padding: 0.25rem 1.25rem 0.1875rem 1.25rem;
  background-color: ${themeGet('programNav.backgroundColor', 'white')};
  box-shadow: ${themeGet('programNav.boxShadow')};
`

export const NavTabLink = styled(TextLink).attrs(props => {
  const { isActive, isLast } = props
  const fontFamily = isActive
    ? themeGet('fontFamilySemibold')(props)
    : themeGet('fontFamily')(props)
  const color = isActive
    ? themeGet('colors.themePrimary')(props)
    : themeGet('colors.mediumGray')(props)
  const marginRight = isLast ? 0 : themeGet('navTabLink.marginRight')(props)

  return {
    color,
    fontFamily,
    marginRight,
  }
})`
  padding: 0.125rem 0;
  color: ${({ color }) => color};
  font-family: ${({ fontFamily }) => fontFamily};
  font-size: 0.9375rem;
  margin-right: ${({ marginRight }) => marginRight};
`
