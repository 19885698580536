import styled from 'styled-components'
import { themeGet } from 'styled-system'
import {
  BackpackV2Icon,
  AwardV2Icon,
  TelescopeIcon,
  ContainedButton,
  OutlinedButton,
} from 'secondstep-components'
import CreateCourse from 'experienceComponents/CreateCourse'

export const StyledBackpackIcon = styled(BackpackV2Icon)`
  margin-top: -0.1rem;
  // scale the backpack relatively smaller than the other spot illustrations:
  scale: 0.9 !important;

  rect,
  line {
    stroke: ${({ theme }) =>
      theme.meta.themeName === 'elementary'
        ? themeGet('colors.elemDigital1')
        : themeGet('colors.blue')};
  }
  path,
  rect:nth-of-type(1) {
    stroke: ${({ theme }) =>
      theme.meta.themeName === 'elementary'
        ? themeGet('colors.elemDigital3')
        : themeGet('colors.darkBlue')}};
  }
  path:last-of-type {
    fill: ${({ theme }) =>
      theme.meta.themeName === 'elementary'
        ? themeGet('colors.lightGreen')
        : themeGet('colors.lighterBlue')};
  }
`

export const StyledAwardIcon = styled(AwardV2Icon)`
  path {
    stroke: ${({ theme }) =>
      theme.meta.themeName === 'elementary'
        ? themeGet('colors.elemDigital3')
        : themeGet('colors.darkBlue')}};
  }
  path:nth-of-type(3) {
    fill: ${({ theme }) =>
      theme.meta.themeName === 'elementary'
        ? themeGet('colors.lightGreen')
        : themeGet('colors.lighterBlue')};
  }
  path:last-of-type {
    stroke: ${({ theme }) =>
      theme.meta.themeName === 'elementary'
        ? themeGet('colors.elemDigital1')
        : themeGet('colors.blue')};
    fill: ${themeGet('colors.white')};
  }
`

export const StyledTelescopeIcon = styled(TelescopeIcon)`
  path {
    stroke: ${({ theme }) =>
      theme.meta.themeName === 'elementary'
        ? themeGet('colors.elemDigital1')
        : themeGet('colors.blue')};
    fill: ${({ theme }) =>
      theme.meta.themeName === 'elementary'
        ? themeGet('colors.lightGreen')
        : themeGet('colors.lighterBlue')};
  }
  line,
  rect {
    stroke: ${({ theme }) =>
      theme.meta.themeName === 'elementary'
        ? themeGet('colors.elemDigital3')
        : themeGet('colors.darkBlue')}};
  }
`

export const StyledContainedButton = styled(ContainedButton)`
  width: 192px;
  max-height: 2.6875rem;

  ${themeGet('breakpoints.mobile')} {
    width: unset;
    max-height: unset;
  }
`

export const StyledOutlinedButton = styled(OutlinedButton)`
  width: 192px;
  max-height: 2.6875rem;

  ${themeGet('breakpoints.mobile')} {
    max-height: unset;
    width: unset;
  }
`

export const StyledCreateCourse = styled(CreateCourse)`
  ${ContainedButton} {
    width: 192px;
    max-height: 2.6875rem;

    ${themeGet('breakpoints.mobile')} {
      width: unset;
      max-height: unset;
    }
  }
`
