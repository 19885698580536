import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import { selectors as licenseSelectors } from 'store/licenseManager'
import HsUserProgress from './component'

const HsUserProgressContainer = ({ activeAdminHsSites }) => {
  const [siteId, setSiteId] = useState(null)
  const location = useLocation()
  const history = useHistory()
  const searchParams = useMemo(() => new URLSearchParams(location.search), [
    location.search,
  ])

  useEffect(() => {
    const searchParamsSiteId =
      searchParams.get('siteId') || activeAdminHsSites?.[0]?.id
    searchParams.set('siteId', searchParamsSiteId)
    history.replace({ search: searchParams.toString() })
    setSiteId(searchParamsSiteId)
  }, [siteId])

  return !siteId ? null : <HsUserProgress siteId={siteId} />
}

const mapStateToProps = state => {
  const activeAdminHsSites = licenseSelectors.selectActiveAdminHsSites(state)

  return {
    activeAdminHsSites,
  }
}

HsUserProgressContainer.propTypes = {
  activeAdminHsSites: PropTypes.array,
  location: PropTypes.object,
  match: PropTypes.object,
}

export default connect(mapStateToProps, null)(HsUserProgressContainer)
