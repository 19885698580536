/* istanbul ignore file */
// we don't test SVGs without arguments
import React from 'react'

const UserManagementSvg = () => {
  return (
    <svg
      aria-hidden="true"
      height="25px"
      role="presentation"
      viewBox="0 0 26 25"
      width="26px"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(1.000000, 1.000000)">
          <path
            d="M24,16 L16.8209939,15.9993957 C16.1186016,14.8016574 15.2691531,13.7517916 14.3066949,12.8931297 C14.7026009,10.9582135 15.4580431,9.33573684 16.4273755,8.28761358 C17.1787484,8.74006305 18.0590184,9 19,9 C19.9410026,9 20.8212909,8.74005145 21.5729725,8.28804687 C23.0273034,9.86112053 24,12.726265 24,16 Z"
            fill="#9EB7C8"
          />
          <ellipse
            cx="19"
            cy="4.00377834"
            fill="#9EB7C8"
            rx="4.125"
            ry="4.00377834"
          />
          <path
            d="M11.7588382,12.4043861 C14.2824083,14.4228964 16,18.4105744 16,23 L0,23 C0,18.4105744 1.71759174,14.4228964 4.2411618,12.4043861 C5.32703478,13.0987289 6.61665645,13.5 8,13.5 C9.36920594,13.5 10.6465959,13.1068889 11.7254611,12.4273754 Z"
            fill="#3C6E90"
          />
          <circle cx="8" cy="6.5" fill="#3C6E90" r="5.5" />
        </g>
      </g>
    </svg>
  )
}

export default UserManagementSvg
