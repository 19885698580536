import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { NavTabLink, NavTabSet } from './component.styles'

class ProgramNav extends Component {
  onClick = route => {
    const { goToProgram } = this.props
    // previously there was more complex logic to handle this, but route-service returns
    // full paths to learn (starting from the root) so we can just use the route directly:
    goToProgram && goToProgram(route)
  }

  render() {
    const { programs } = this.props
    if (!programs) {
      return null
    }
    return (
      <NavTabSet data-testid="program-nav-tabset">
        {programs.map((program, i) => {
          const { isSelected, route, title } = program || {}

          if (!route || !title || !program) {
            return null
          }

          const unitNumber = i + 1
          const isActive = isSelected || false
          const isLast = programs.length === i + 1
          const unitId = `${title}`
          const dataTestId = `nav-tab-${unitId}-${unitNumber}`
          const search = location?.search
          return (
            <NavTabLink
              dataTestId={dataTestId}
              isActive={isActive}
              isLast={isLast}
              key={dataTestId}
              onClick={() => {
                this.onClick(route + search)
              }}
            >
              {title}
            </NavTabLink>
          )
        })}
      </NavTabSet>
    )
  }
}

ProgramNav.propTypes = {
  goToProgram: PropTypes.func.isRequired,
  location: PropTypes.shape({ pathname: PropTypes.string }).isRequired,
  programs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      route: PropTypes.string,
      isSelected: PropTypes.bool,
      entryId: PropTypes.string,
    }),
  ),
}

export default ProgramNav
