import React from 'react'
import PropTypes from 'prop-types'
import { Redirect, Route, Switch } from 'react-router'
import { NotFound } from 'secondstep-components'
import HsUserProgress from 'pages/HsUserProgress'
import LearnErrorBoundary from 'components/LearnErrorBoundary'
import validRoutesMap from './validRoutesMap'

export const UserProgressRouter = ({
  lastViewedType = 'student-activities',
  match,
}) => {
  const checkIsValidRoute = routeProps => {
    const interactionType = routeProps.match.params.interactionType
    return validRoutesMap[interactionType] ? (
      <HsUserProgress {...routeProps} />
    ) : (
      <NotFound location={routeProps.location} />
    )
  }
  return (
    <LearnErrorBoundary>
      <Switch>
        <Route
          path={`${match.path}/:interactionType`}
          render={checkIsValidRoute}
        />
        <Redirect
          exact
          from={`${match.path}`}
          to={`${match.path}/${lastViewedType}`}
        />
        <Route component={NotFound} />
      </Switch>
    </LearnErrorBoundary>
  )
}

UserProgressRouter.propTypes = {
  lastViewedType: PropTypes.string,
  match: PropTypes.object,
}

export default UserProgressRouter
