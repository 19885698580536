import styled from 'styled-components'
import { Box } from 'grommet'
import { themeGet } from 'styled-system'
import { ButtonsWrapper } from '../component.styles'
import {
  FormInputSelect,
  FormInputText,
  TextLink,
  BaseContainer,
} from 'secondstep-components'

export const Row = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`
export const Column = styled.div`
  display: flex;
  flex-direction: column;
`
export const Link = styled(TextLink)`
  padding-left: 5px;
  text-decoration: underline;
  color: ${themeGet('colors.blueGray')};
  font-family: ${themeGet('font.molde.regular')};
`

export const Text = styled.p`
  :has(${Link}) {
    display: flex;
    flex-flow: wrap;
    gap: 5px;

    ${Link} {
      padding-left: 0;
    }
  }

  font-weight: ${({ variant }) => (variant === 'bold' ? 400 : 'unset')};
  font-family: ${({ variant }) =>
    variant === 'bold'
      ? themeGet('font.molde.medium')
      : themeGet('font.molde.regular')};

  & > b {
    font-family: ${themeGet('font.molde.medium')};
    font-weight: 400;
  }
  font-size: 16px;
  color: ${themeGet('colors.darkBlue')};
  letter-spacing: 0;
  line-height: 22px;
  display: inline-box;
`

export const SectionContainer = styled(BaseContainer)`
  background-color: ${themeGet('colors.lighterGray')};
  padding: ${themeGet('spacing.amber28_1_75rem')};
  margin-top: ${themeGet('spacing.turquoise20_1_25rem')};
  margin-bottom: ${themeGet('spacing.turquoise20_1_25rem')};
`
export const ButtonsWrapperCreate = styled(ButtonsWrapper)`
  position: unset;
  transform: unset;
  left: unset;
  margin-top: 1.25rem;
  bottom: 0;
  button[type='submit'] {
    &:not([disabled]) {
      background-color: ${themeGet('colors.blue')};
      border-color: ${themeGet('colors.blue')};

      &:hover {
        background: ${themeGet('colors.darkBlue')} !important;
        border-color: ${themeGet('colors.darkBlue')} !important;
      }
    }
  }

  ${themeGet('breakpoints.mobile')} {
    justify-content: center;
  }
`

export const SelectField = styled(FormInputSelect)`
  margin-bottom: ${themeGet('spacing.turquoise20_1_25rem')};
  button {
    border: 1.5px solid ${themeGet('colors.blueGrayMedium')};
  }
  label {
    font-family: ${themeGet('font.molde.medium')};
    font-size: 0.75rem;
    line-height: ${themeGet('spacing.turquoise20_1_25rem')};
  }

  label,
  input {
    color: ${themeGet('colors.darkGray')};
  }
`

export const InputTextField = styled(FormInputText)`
  margin-bottom: ${themeGet('spacing.turquoise20_1_25rem')};
  label,
  input {
    color: ${themeGet('colors.darkGray')};
    font-family: ${themeGet('font.molde.medium')};
    line-height: ${themeGet('spacing.turquoise20_1_25rem')};
  }
  label {
    font-size: 0.75rem;
  }
  input {
    border: 1.5px solid #8393af;
    font-size: 1rem;
  }
`
