import { variables } from 'themes'

export const BANNER_STRONG_TEXT =
  'This page is for previewing lessons in this grade.'
export const BANNER_LINK_TEXT =
  'To teach lessons to students and track progress, <0>create or go to a class.</0>'
export const BANNER_SCROLL_THRESHOLD = 160
export const MARGIN_TOP_DEFAULT = '-7.75rem'
export const MARGIN_TOP_MOBILE = '-7.75rem'
export const MARGIN_TOP_SCROLL = '0'
export const MOBILE_LARGE_BREAKPOINT = parseInt(
  variables.breakpoints.sizes.mobileLarge.replace('px', ''),
)
