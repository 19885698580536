import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { OutlinedButton } from 'secondstep-components'

export const BottomWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1.25rem;

  span {
    color: ${themeGet('colors.darkGray')};
  }

  ${themeGet('breakpoints.tablet')} {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
`

export const ButtonsWrapper = styled.div`
  display: flex;

  button {
    margin-left: 1.25rem;
  }

  ${OutlinedButton} {
    border-color: ${themeGet('colors.gray600')};
    color: ${themeGet('colors.gray1100')};
  }

  ${themeGet('breakpoints.tablet')} {
    margin-top: 0.5rem;

    button {
      &:nth-child(1) {
        margin: 0;
      }
    }
  }

  ${themeGet('breakpoints.mobile')} {
    flex-direction: column;

    button {
      margin: 0;
    }

    button {
      &:nth-child(2) {
        margin-top: 1.25rem;
      }
    }
  }
`

export const ExpiredLicensesNotificationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 0.8125rem;
  margin-bottom: 1.25rem;
`

export const NotificationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: ${themeGet('colors.white')};
  margin-top: 1.25rem;
  border-radius: 0.8125rem;
`

export const TextWrapper = styled.div`
  a {
    display: inline-flex;
    align-items: center;
    font-size: 1rem;
    line-height: 1.25rem;
  }

  ul {
    padding: 0;
    margin: 0;

    li {
      list-style: none;
      font-size: 1rem;
      line-height: 1.25rem;
    }
  }
`

export const TopWrapper = styled.div`
  display: flex;
  padding: 1.125rem 1.25rem 1.125rem;
  border-radius: 0.8125rem 0.8125rem 0 0;
  border-bottom: 1px solid ${themeGet('colors.paleGray')};

  h5 {
    margin-bottom: 0.25rem;
    font-family: ${themeGet('font.molde.semiBold')};
    font-size: 1.0625rem;
  }

  .yield-icon {
    min-width: 1.25rem;
    width: 1.25rem;
    min-height: 1.0625rem;
    height: 1.0625rem;
    margin-top: 0.125rem;
    margin-right: 0.5rem;
  }

  .right-arrow-icon {
    min-width: 0.75rem;
    width: 0.75rem;
    min-height: 0.625rem;
    height: 0.625rem;
    margin: 0;
    margin-left: 0.25rem;
  }
`
