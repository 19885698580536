import React, { Fragment, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Element, scroller } from 'react-scroll'
import { SpinnerAnimatedIcon } from 'secondstep-components'
import {
  formatManageUsersLinkLabel,
  formatReportLinkLabel,
  formatUserSetupLinkLabel,
} from 'utils/adminExperienceHelpers'
import {
  formatHoursSinceTimestamp,
  formatPageCount,
  formatSiteCount,
} from 'utils/reportsHelpers'
import { TOOLTIP_LOCATIONS, TOOLTIP_PROGRAMS } from 'utils/tooltipHelpers'
import AdminExportDownloadButton from 'components/AdminExportDownloadButton'
import AdminSortButton from 'components/AdminSortButton'
import AdminUsersWithClassesCard from 'components/AdminUsersWithClassesCard'
import K8ClassesCreatedPanel from 'components/K8ClassesCreatedPanel'
import LessonCompletionAccordion from 'components/LessonCompletionAccordion'
import {
  Bullet,
  ContentWrapper,
  CountCopy,
  CountWrapper,
  FixedHeaderWrapper,
  HeaderRightBox,
  HeaderWrapper,
  LeftBox,
  LoadingWrapper,
  MultiSiteLeftBox,
  NameWrapper,
  NoClassesContainer,
  NoClassesCopy,
  PaginationButton,
  PaginationIndexContainer,
  PaginationPageWrapper,
  PaginationSiteWrapper,
  PanelWrapper,
  RightBox,
  SingleSite,
  SingleSiteLeftBox,
  SiteCollapsible,
  SiteListContainer,
  SiteListFooter,
  SiteListHeader,
  StyledAdminK8UsersCard,
  StyledAdminSortButton,
  StyledBodyCopy,
  StyledHeaderTwo,
  StyledHr,
  UnitsWrapper,
  Wrapper,
} from './component.styles'
import {
  CLASSES_HEADER_TEXT,
  LESSONS_HEADER_TEXT,
  NEXT_BUTTON_TEXT,
  NO_CLASSES_TEXT,
  ORDER_BY,
  PREVIOUS_BUTTON_TEXT,
  SITES_HEADER_TEXT,
  SORT_KEYS,
  SUBHEADING_TEXT,
  USERS_HEADER_TEXT,
} from './constants'

export const getSortOrder = (
  selectedSortKey,
  currentSortKey,
  currentOrderBy,
) => {
  let selectedOrderBy = ORDER_BY.ASC
  if (selectedSortKey === currentSortKey) {
    selectedOrderBy =
      currentOrderBy === ORDER_BY.ASC ? ORDER_BY.DESC : ORDER_BY.ASC
  }
  return selectedOrderBy
}

const AdminSiteList = ({
  currentPage,
  currentSiteCount,
  getPaginatedSites,
  isFetching,
  lastUpdatedAt,
  monitorView,
  navigationEvent,
  orderBy,
  sites,
  sitesPerPage,
  sitesPreferences,
  sortKey,
  totalPages,
  totalSites,
}) => {
  const [activeIndex, setActiveIndex] = useState(null)
  const [isScrolled, setIsScrolled] = useState(false)
  const hrRef = useRef(null)

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = hrRef?.current?.getBoundingClientRect()?.top
      setIsScrolled(scrollPosition <= 0)
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    window.history.scrollRestoration = 'manual'
    setActiveIndex(null)
  }, [currentPage, orderBy, sites, sortKey])

  const hasMultipleSites = sites?.length > 1 || currentPage > 1
  const siteStartIndex = (currentPage - 1) * sitesPerPage + 1
  const siteEndIndex = siteStartIndex + currentSiteCount - 1

  const showPaginationButtons = totalPages > 1

  const handleAccordionClick = index => {
    if (activeIndex === index) {
      setActiveIndex(null)
    } else {
      setActiveIndex(index)
    }
  }

  const handleSort = option => {
    setActiveIndex(null)
    let order
    if (option === sortKey) {
      order = orderBy === 'asc' ? 'desc' : 'asc'
    } else {
      order = 'asc'
    }
    getPaginatedSites(currentPage, option, order)
  }

  const scrollToContentWrapper = () => {
    scroller.scrollTo('scroll-to-admin', {
      duration: 200,
      delay: 50,
      smooth: true,
      offset: 60,
    })
  }

  const scrollToTopOfTable = () => {
    scroller.scrollTo('hr', {
      duration: 200,
      delay: 50,
      smooth: true,
      offset: 1,
    })
  }

  if (isFetching) {
    return (
      <Wrapper>
        <Element name="content-wrapper" />
        {hasMultipleSites && (
          <>
            <StyledHeaderTwo>{SITES_HEADER_TEXT}</StyledHeaderTwo>
            <StyledBodyCopy>{SUBHEADING_TEXT}</StyledBodyCopy>
            <StyledHr />
          </>
        )}
        <LoadingWrapper>
          <SpinnerAnimatedIcon />
          <p>Loading...</p>
        </LoadingWrapper>
      </Wrapper>
    )
  }

  return (
    <Wrapper monitorView={monitorView}>
      {hasMultipleSites && !monitorView && (
        <HeaderWrapper key="launch-key-1">
          <LeftBox>
            <StyledHeaderTwo>{SITES_HEADER_TEXT}</StyledHeaderTwo>
            <StyledBodyCopy>{SUBHEADING_TEXT}</StyledBodyCopy>
          </LeftBox>
          <HeaderRightBox>
            <AdminExportDownloadButton
              programKey={TOOLTIP_PROGRAMS.K8_MONITOR}
              tooltipLocation={TOOLTIP_LOCATIONS.EXPORT_BUTTON}
            />
          </HeaderRightBox>
        </HeaderWrapper>
      )}
      {hasMultipleSites && monitorView && (
        <Fragment key="monitor-multi-site-key">
          <StyledHr className="table-hr" isScrolled={isScrolled} ref={hrRef} />
          <FixedHeaderWrapper isScrolled={isScrolled}>
            <SiteListHeader
              className="site-list-header"
              isScrolled={isScrolled}
            >
              <StyledAdminSortButton
                className="school"
                handleOnClick={() => {
                  handleSort('name')
                  scrollToTopOfTable()
                }}
                isActive={sortKey === 'name'}
                isCaretUp={sortKey === 'name' && orderBy === 'desc'}
                monitorView
                programKey={TOOLTIP_PROGRAMS.K8_MONITOR}
                title={SITES_HEADER_TEXT}
                tooltipLocation={TOOLTIP_LOCATIONS.TABLE_SCHOOLS}
              />
              <StyledAdminSortButton
                className="classes"
                handleOnClick={() => {
                  handleSort('classesStarted')
                  scrollToTopOfTable()
                }}
                isActive={sortKey === 'classesStarted'}
                isCaretUp={sortKey === 'classesStarted' && orderBy === 'desc'}
                monitorView
                programKey={TOOLTIP_PROGRAMS.K8_MONITOR}
                title={CLASSES_HEADER_TEXT}
                tooltipLocation={TOOLTIP_LOCATIONS.TABLE_CLASSES}
              />
              <StyledAdminSortButton
                className="lessons"
                handleOnClick={() => {
                  handleSort('completedLessons')
                  scrollToTopOfTable()
                }}
                isActive={sortKey === 'completedLessons'}
                isCaretUp={sortKey === 'completedLessons' && orderBy === 'desc'}
                monitorView
                programKey={TOOLTIP_PROGRAMS.K8_MONITOR}
                title={LESSONS_HEADER_TEXT}
                tooltipLocation={TOOLTIP_LOCATIONS.TABLE_LESSONS}
              />
              <UnitsWrapper>
                <StyledAdminSortButton
                  className="unit"
                  handleOnClick={() => {
                    handleSort('unit1')
                    scrollToTopOfTable()
                  }}
                  isActive={sortKey === 'unit1'}
                  isCaretUp={sortKey === 'unit1' && orderBy === 'desc'}
                  monitorView
                  programKey={TOOLTIP_PROGRAMS.K8_MONITOR}
                  title={'Unit 1'}
                  tooltipLocation={TOOLTIP_LOCATIONS.TABLE_UNIT_1}
                />
                <StyledAdminSortButton
                  className="unit"
                  handleOnClick={() => {
                    handleSort('unit2')
                    scrollToTopOfTable()
                  }}
                  isActive={sortKey === 'unit2'}
                  isCaretUp={sortKey === 'unit2' && orderBy === 'desc'}
                  monitorView
                  programKey={TOOLTIP_PROGRAMS.K8_MONITOR}
                  title={'Unit 2'}
                  tooltipLocation={TOOLTIP_LOCATIONS.TABLE_UNIT_2}
                />
                <StyledAdminSortButton
                  className="unit"
                  handleOnClick={() => {
                    handleSort('unit3')
                    scrollToTopOfTable()
                  }}
                  isActive={sortKey === 'unit3'}
                  isCaretUp={sortKey === 'unit3' && orderBy === 'desc'}
                  monitorView
                  programKey={TOOLTIP_PROGRAMS.K8_MONITOR}
                  title={'Unit 3'}
                  tooltipLocation={TOOLTIP_LOCATIONS.TABLE_UNIT_3}
                />
                <StyledAdminSortButton
                  className="unit"
                  handleOnClick={() => {
                    handleSort('unit4')
                    scrollToTopOfTable()
                  }}
                  isActive={sortKey === 'unit4'}
                  isCaretUp={sortKey === 'unit4' && orderBy === 'desc'}
                  monitorView
                  programKey={TOOLTIP_PROGRAMS.K8_MONITOR}
                  title={'Unit 4'}
                  tooltipLocation={TOOLTIP_LOCATIONS.TABLE_UNIT_4}
                />
              </UnitsWrapper>
            </SiteListHeader>
          </FixedHeaderWrapper>
          <SiteListContainer dataTestId={'monitor-site-list'}>
            {sites?.map((site, index) => (
              <LessonCompletionAccordion
                {...site}
                activeIndex={activeIndex}
                handleAccordionClick={handleAccordionClick}
                index={index}
                key={`site-${index}`}
                lastUpdatedAt={lastUpdatedAt}
                sitePreferences={sitesPreferences[site.siteId]}
              />
            ))}
          </SiteListContainer>
          {hasMultipleSites && (
            <SiteListFooter>
              {showPaginationButtons && (
                <PaginationButton
                  disabled={currentPage <= 1}
                  onClick={() => {
                    getPaginatedSites(currentPage - 1, sortKey, orderBy)
                    scrollToContentWrapper()
                  }}
                >
                  {PREVIOUS_BUTTON_TEXT}
                </PaginationButton>
              )}
              <PaginationIndexContainer>
                <PaginationPageWrapper>
                  {formatPageCount(currentPage, totalPages)}
                </PaginationPageWrapper>
                <PaginationSiteWrapper>
                  {formatSiteCount(siteStartIndex, siteEndIndex, totalSites)}
                </PaginationSiteWrapper>
              </PaginationIndexContainer>
              {showPaginationButtons && (
                <PaginationButton
                  disabled={currentPage >= totalPages}
                  onClick={() => {
                    getPaginatedSites(currentPage + 1, sortKey, orderBy)
                    scrollToContentWrapper()
                  }}
                >
                  {NEXT_BUTTON_TEXT}
                </PaginationButton>
              )}
            </SiteListFooter>
          )}
        </Fragment>
      )}
      {!monitorView && (
        <Fragment key="launch-key-2">
          {hasMultipleSites && (
            <Fragment key="launch-key-3">
              <StyledHr />
              <SiteListHeader>
                <AdminSortButton
                  handleOnClick={() => {
                    getPaginatedSites(
                      currentPage,
                      SORT_KEYS[SITES_HEADER_TEXT],
                      getSortOrder(
                        SORT_KEYS[SITES_HEADER_TEXT],
                        sortKey,
                        orderBy,
                      ),
                    )
                    scrollToContentWrapper()
                  }}
                  isActive={sortKey === SORT_KEYS[SITES_HEADER_TEXT]}
                  isCaretUp={orderBy === ORDER_BY.ASC}
                  programKey={TOOLTIP_PROGRAMS.K8}
                  title={SITES_HEADER_TEXT}
                  tooltipLocation={TOOLTIP_LOCATIONS.LAUNCH_SITE}
                />
                <AdminSortButton
                  handleOnClick={() => {
                    getPaginatedSites(
                      currentPage,
                      SORT_KEYS[USERS_HEADER_TEXT],
                      getSortOrder(
                        SORT_KEYS[USERS_HEADER_TEXT],
                        sortKey,
                        orderBy,
                      ),
                    )
                    scrollToContentWrapper()
                  }}
                  isActive={sortKey === SORT_KEYS[USERS_HEADER_TEXT]}
                  isCaretUp={orderBy === ORDER_BY.ASC}
                  programKey={TOOLTIP_PROGRAMS.K8}
                  title={USERS_HEADER_TEXT}
                  tooltipLocation={TOOLTIP_LOCATIONS.LAUNCH_USERS}
                />
                <AdminSortButton
                  handleOnClick={() => {
                    getPaginatedSites(
                      currentPage,
                      SORT_KEYS[CLASSES_HEADER_TEXT],
                      getSortOrder(
                        SORT_KEYS[CLASSES_HEADER_TEXT],
                        sortKey,
                        orderBy,
                      ),
                    )
                    scrollToContentWrapper()
                  }}
                  isActive={sortKey === SORT_KEYS[CLASSES_HEADER_TEXT]}
                  isCaretUp={orderBy === ORDER_BY.ASC}
                  programKey={TOOLTIP_PROGRAMS.K8}
                  title={CLASSES_HEADER_TEXT}
                  tooltipLocation={TOOLTIP_LOCATIONS.LAUNCH_CLASSES}
                />
              </SiteListHeader>
            </Fragment>
          )}
          {hasMultipleSites && (
            <SiteListContainer>
              {sites?.map((site, index) => {
                const {
                  adminsAcceptedCount,
                  adminsPendingCount,
                  classesCreated,
                  classesCreatedLastUpdated,
                  siteId,
                  siteName,
                  teachersAcceptedCount,
                  teachersPendingCount,
                  totalUsersCount,
                  usersWithClassesCount,
                  usersWithClassesLastUpdated,
                  usersWithoutClassesCount,
                } = site

                const { total: totalClasses } = classesCreated || {}
                return (
                  <SiteCollapsible
                    dataTestId={`site-collapsible-${siteId}`}
                    followActiveProp={true}
                    isActive={activeIndex === index}
                    key={siteId}
                    onClick={() => handleAccordionClick(index)}
                    title={
                      <PanelWrapper>
                        <NameWrapper>{siteName}</NameWrapper>
                        <CountWrapper>
                          <CountCopy>{totalUsersCount}</CountCopy>
                          {adminsPendingCount + teachersPendingCount > 0 && (
                            <span>
                              {adminsPendingCount + teachersPendingCount}{' '}
                              pending
                            </span>
                          )}
                        </CountWrapper>
                        <CountWrapper>
                          {totalClasses > 0 && (
                            <CountCopy>{totalClasses}</CountCopy>
                          )}
                          {totalClasses === 0 && (
                            <NoClassesContainer>
                              <Bullet />
                              <NoClassesCopy>{NO_CLASSES_TEXT}</NoClassesCopy>
                            </NoClassesContainer>
                          )}
                        </CountWrapper>
                      </PanelWrapper>
                    }
                  >
                    <ContentWrapper
                      dataTestId={`site-collapsible-content-${siteId}`}
                    >
                      <MultiSiteLeftBox>
                        <StyledAdminK8UsersCard
                          countData={{
                            totalUniqueUsers: totalUsersCount,
                            administrators: {
                              active: adminsAcceptedCount,
                              pending: adminsPendingCount,
                            },
                            teachers: {
                              active: teachersAcceptedCount,
                              pending: teachersPendingCount,
                            },
                          }}
                          linkLabel={formatManageUsersLinkLabel(siteName)}
                          siteId={siteId}
                        />
                        <AdminUsersWithClassesCard
                          className="tooltip-lines-5"
                          lastUpdated={formatHoursSinceTimestamp(
                            usersWithClassesLastUpdated,
                          )}
                          linkLabel={formatUserSetupLinkLabel(siteName)}
                          navigationEvent={navigationEvent}
                          siteId={siteId}
                          usersWithClassesCount={usersWithClassesCount}
                          usersWithoutClassesCount={usersWithoutClassesCount}
                        />
                      </MultiSiteLeftBox>
                      <RightBox>
                        <K8ClassesCreatedPanel
                          classesCreated={classesCreated}
                          lastUpdated={formatHoursSinceTimestamp(
                            classesCreatedLastUpdated,
                          )}
                          licenseOrSiteId={siteId}
                          linkLabel={formatReportLinkLabel(siteName)}
                        />
                      </RightBox>
                    </ContentWrapper>
                  </SiteCollapsible>
                )
              })}
            </SiteListContainer>
          )}
          {!hasMultipleSites && !!sites[0] && (
            <SingleSite>
              <PanelWrapper>
                <StyledHeaderTwo>{sites[0].siteName}</StyledHeaderTwo>
                <StyledBodyCopy>{SUBHEADING_TEXT}</StyledBodyCopy>
              </PanelWrapper>
              <ContentWrapper
                dataTestId={`site-collapsible-content-${sites[0].id}`}
              >
                <SingleSiteLeftBox>
                  <StyledAdminK8UsersCard
                    countData={{
                      totalUniqueUsers: sites[0].totalUsersCount,
                      administrators: {
                        active: sites[0].adminsAcceptedCount,
                        pending: sites[0].adminsPendingCount,
                      },
                      teachers: {
                        active: sites[0].teachersAcceptedCount,
                        pending: sites[0].teachersPendingCount,
                      },
                    }}
                    linkLabel={formatManageUsersLinkLabel(sites[0].siteName)}
                    navigationEvent={navigationEvent}
                    siteId={sites[0].siteId}
                  />
                  <AdminUsersWithClassesCard
                    linkLabel={formatUserSetupLinkLabel(sites[0].siteName)}
                    navigationEvent={navigationEvent}
                    siteId={sites[0].siteId}
                    usersWithClassesCount={sites[0].usersWithClassesCount}
                    usersWithoutClassesCount={sites[0].usersWithoutClassesCount}
                  />
                </SingleSiteLeftBox>
                <RightBox>
                  <K8ClassesCreatedPanel
                    classesCreated={sites[0].classesCreated}
                    licenseOrSiteId={sites[0].siteId}
                    linkLabel={formatReportLinkLabel(sites[0].siteName)}
                  />
                </RightBox>
              </ContentWrapper>
            </SingleSite>
          )}
          {hasMultipleSites && (
            <SiteListFooter>
              {showPaginationButtons && (
                <PaginationButton
                  disabled={currentPage <= 1}
                  onClick={() => {
                    getPaginatedSites(currentPage - 1, sortKey, orderBy)
                    scrollToContentWrapper()
                  }}
                >
                  {PREVIOUS_BUTTON_TEXT}
                </PaginationButton>
              )}
              <PaginationIndexContainer>
                <PaginationPageWrapper>
                  {formatPageCount(currentPage, totalPages)}
                </PaginationPageWrapper>
                <PaginationSiteWrapper>
                  {formatSiteCount(siteStartIndex, siteEndIndex, totalSites)}
                </PaginationSiteWrapper>
              </PaginationIndexContainer>
              {showPaginationButtons && (
                <PaginationButton
                  disabled={currentPage >= totalPages}
                  onClick={() => {
                    getPaginatedSites(currentPage + 1, sortKey, orderBy)
                    scrollToContentWrapper()
                  }}
                >
                  {NEXT_BUTTON_TEXT}
                </PaginationButton>
              )}
            </SiteListFooter>
          )}
        </Fragment>
      )}
    </Wrapper>
  )
}

AdminSiteList.propTypes = {
  currentPage: PropTypes.number,
  currentSiteCount: PropTypes.number,
  getPaginatedSites: PropTypes.func,
  isFetching: PropTypes.bool,
  lastUpdatedAt: PropTypes.object,
  loadSites: PropTypes.func,
  monitorView: PropTypes.bool,
  navigationEvent: PropTypes.func,
  orderBy: PropTypes.string,
  sites: PropTypes.array,
  sitesPerPage: PropTypes.number,
  sitesPreferences: PropTypes.array,
  sortKey: PropTypes.string,
  totalPages: PropTypes.number,
  totalSites: PropTypes.number,
}

export default AdminSiteList
