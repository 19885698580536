import React from 'react'
import PropTypes from 'prop-types'

const HsUserProgress = ({ siteId }) => {
  if (!siteId) return null
  return <h1>Hs User Progress</h1>
}

HsUserProgress.propTypes = {
  siteId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default HsUserProgress
