import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Box } from 'grommet'
import { HeaderThree } from 'secondstep-components'

export const TilesContainer = styled(Box)`
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1.25rem;
  padding: 0.625rem 0.625rem 1.25rem 0.625rem;
  min-width: 18.4375rem;

  ${HeaderThree} {
    width: 100%;
  }

  ${themeGet('breakpoints.xlarge')} {
    min-width: 39.375rem;
  }
  ${themeGet('breakpoints.tablet')} {
    min-width: 0;
  }
`
