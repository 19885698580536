/* istanbul ignore file */
// we don't test SVGs without arguments
import React from 'react'

const FlagSvg = () => {
  return (
    <svg
      aria-hidden="true"
      height="30px"
      role="presentation"
      viewBox="0 0 25 30"
      width="25px"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fillRule="nonzero">
          <path
            d="M8.70481755,27.8316214 C8.88857469,28.5910184 8.42788073,29.3570023 7.67593772,29.5425305 C6.92464527,29.7274522 6.16618001,29.2621914 5.9824896,28.5034514 L0.0403856926,5.52965848 C-0.143371444,4.7709185 0.317322511,4.00493463 1.06926552,3.8194233 C1.82120853,3.63450159 2.57902323,4.09910538 2.76271365,4.85850236 L8.70481755,27.8316214 Z"
            fill="#3C6E90"
          />
          <path
            d="M5.96144899,1.39059285 C8.87210466,-0.3533349 12.2630043,-0.621696271 15.7484849,1.53733919 C16.2254594,1.83280623 17.7398544,2.79555476 18.0342729,2.9778316 C18.777742,3.4384765 19.3224911,3.74381547 19.8346458,3.98070798 C21.0003593,4.51899743 22.0533764,4.70786116 23.4770939,4.58479903 C24.5528805,4.49135435 25.3243402,5.61137814 24.8643468,6.597762 C22.6893202,11.2581192 19.4874155,13.0487111 14.7150011,11.9892481 C12.7139566,11.545062 10.1017228,12.7651039 9.23898506,15.2111007 C8.775055,16.5259029 6.90498918,16.4397005 6.56152881,15.0873849 L3.78048398,4.1314014 C3.65993756,3.65562852 3.79221284,3.15089688 4.12974931,2.7975123 C4.49087479,2.41979998 5.10923691,1.90125439 5.96144899,1.39059285 Z"
            fill="#9EB7C8"
          />
        </g>
      </g>
    </svg>
  )
}

export default FlagSvg
