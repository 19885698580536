// REMINDER: Please do not commit changes inside the object. Thanks!

// used to hardcode override values for local development
// also used to prevent reloading of a flag from remote changes
// example:

export default {
  // 'feature_learn-17857_doc-svc-k8-flex-export': true,
  // NOTE: uncomment the next line to enable k5 program flex:
  // 'feature_LEARN-18511-k5-elem-program-flex': true,
}
