import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { BodyCopy, HeaderThree, TextLink } from 'secondstep-components'

export const CategoryCalloutWrapper = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: ${themeGet('spacing.blue16_1rem')};
  max-width: 25.938rem;
  box-sizing: border-box;
  background-color: ${({ isHovered }) =>
    isHovered
      ? themeGet('callouts.categoryBackgroundHover', 'colors.themeHighlight')
      : themeGet('callouts.categoryTileBackground', 'colors.themePrimary')};
  border-radius: 10px;
  border: ${themeGet('callouts.categoryBorder', 'unset')};
  cursor: pointer;
  text-decoration: none;
  padding: ${themeGet('spacing.amber28_1_75rem')};

  ${themeGet('breakpoints.tablet')} {
    flex-wrap: nowrap;
  }

  ${themeGet('breakpoints.mobileLarge')} {
    padding: ${themeGet('spacing.amber28_1_75rem')};
    ${themeGet('spacing.turquoise20_1_25rem')};
  }
  h3 {
    color: ${themeGet('callouts.categoryTileH3Color')};
  }
  span,
  p {
    color: ${themeGet('callouts.categoryBodyCopyColor')};
    ${themeGet('breakpoints.mobile')} {
      max-width: 70% !important;
    }
  }
  :hover {
    svg path {
      fill: ${themeGet('callouts.svgFillColor')};
    }
  }
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  z-index: 1;
`

export const LinkStyled = styled(TextLink)`
  width: 100%;
  padding: 0;
  margin: 0;
  text-decoration: none;
`

export const HeaderText = styled(HeaderThree).attrs(props => {
  const isPlatformTheme =
    themeGet('callouts.categoryBackground')(props) ===
    themeGet('colors.paleGray')(props)
  const headerTextColor = isPlatformTheme
    ? themeGet('colors.secondStepBlue')(props)
    : themeGet('colors.white')(props)

  return {
    headerTextColor,
  }
})`
  color: ${({ headerTextColor }) => headerTextColor};
  margin-bottom: ${themeGet('spacing.turquoise20_1_25rem')};
  font-size: 1.375rem;
  font-family: ${themeGet('fontFamilySemibold')};
`

export const BodyText = styled(BodyCopy)`
  color: ${props => {
    const isPlatformTheme =
      themeGet('callouts.categoryBackground')(props) ===
      themeGet('colors.paleGray')(props)
    const bodyTextColor = isPlatformTheme
      ? themeGet('colors.darkGray')(props)
      : themeGet('colors.white')(props)

    return bodyTextColor
  }};
  margin-top: 0.6875rem;
  font-family: ${themeGet('textFontFamily')};
`

export const IconWrapper = styled.div.attrs(props => {
  const svgColor = themeGet(
    'callouts.categoryBackgroundHover',
    'colors.themeHighlight',
  )
  const svgHoverColor = themeGet(
    'callouts.categoryTileBackground',
    'colors.themePrimary',
  )

  return {
    svgColor,
    svgHoverColor,
  }
})`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  svg {
    color: ${({ isHovered, svgColor, svgHoverColor }) =>
      isHovered ? svgHoverColor : svgColor};
  }
  ${themeGet('breakpoints.tablet')} {
    align-items: center;
  }
  ${themeGet('breakpoints.mobile')} {
    position: absolute;
    bottom: ${themeGet('spacing.turquoise20_1_25rem')};
    right: ${themeGet('spacing.turquoise20_1_25rem')};
  }
`
