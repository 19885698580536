import { MOCK_RESOURCE_LIBRARY_DATA_FLAG } from 'utils/highschoolHelpers'

// Add in the flag name of flags as strings.
// REQUIRED for any flags to work. Subscribe here for the app to listen to any of these flags and get values
// For example:
// ['feature_learn-2790_react-poc_01-2020']
export default [
  'feature_learn-14107_HS',
  'feature_learn-15902_HS_LIBRARY',
  'feature_learn-16048_WEBINAR_GATING',
  'feature_LEARN-16283_mind_yeti_on_families_dashboard',
  'feature_LEARN-16712_learn-video-v3',
  'middle-school-june-release',
  'high-school-june-release',
  'middle-school-leader-dashboard-optin-functionality-release-2',
  'hs-admin-single-site-explore-programs-pacing',
  MOCK_RESOURCE_LIBRARY_DATA_FLAG,
  'feature_LEARN-18031_pacing_my-programs_redo',
  'feature_learn-18030_show-completion-hs-dashboard',
  'feature_learn-18110_add-training-summary-mark-as-done',
  'feature-learn-17909-program-flex-family-resources',
  'hs-admin-training-and-kickoff-data',
  'feature_learn-18371_survey-learning-experience',
  'feature_LEARN-17442_Educator_profile',
  'feature_learn-17857_doc-svc-k8-flex-export',
  'feature_learn-18731_doc-svc-hs-full-export',
  'feature_LEARN-18123-filter-educator-profiles',
  'feature_LEARN-18248-hs-family-resources',
  'feature_learn-18531-hs-monitor',
  'feature_learn-18453-hs-student-extension-activities',
  'feature_LEARN-18511-k5-elem-program-flex',
  'feature_learn-18535_hs-admin-monitor-pathway-progress',
  'high-school-snapshot-role-count-and-layout-update',
  'feature_learn-18822-HS-user-progress-reporting',
]
