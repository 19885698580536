import React from 'react'
import PropTypes from 'prop-types'
import { deepMerge } from 'grommet/utils'
import { ThemeProvider } from 'styled-components'
import { HeaderTwo } from 'secondstep-components'
import { toUrlSafe } from 'utils/stringHelpers'
import CreateCourse from 'experienceComponents/CreateCourse'
import SiteSelector from 'components/SiteSelector'
import ProgramRoleTile from 'components/ProgramRoleTile'
import {
  Background,
  Body,
  Content,
  HeaderBox,
  HighSchoolProgramHeader,
  TileContainer,
} from './component.styles'
import themeMap from './data'
import ProgramTitle from './ProgramTitle'
import { superscriptRegistered } from './helpers'

const ProgramCard = React.forwardRef(
  (
    {
      children,
      classesUrl,
      contentfulCourses,
      currentSite,
      displayTitle,
      displayTitleLink,
      isHighSchool,
      isHighSchoolFamily,
      isHsInstancedView,
      isK5Product,
      isMindYetiProduct,
      isMsProduct,
      isOstProduct,
      isSelaProduct,
      firstTime,
      onComponentClick,
      program,
      theme,
    },
    ref,
  ) => {
    const { highSchoolEducatorPreferences, isSetup } = currentSite || {}
    const { hasUpdatedProfile } = highSchoolEducatorPreferences || {}
    const programTheme = deepMerge(theme, themeMap[program])
    const headerTestId = `header-${toUrlSafe(displayTitle)}`

    return (
      <ThemeProvider theme={programTheme}>
        <Background
          dataTestId={`program-card-${toUrlSafe(program)}`}
          isHighSchoolFamily={isHighSchoolFamily}
          isMindYetiProduct={isMindYetiProduct}
          isOstProduct={isOstProduct}
          isSelaProduct={isSelaProduct}
        >
          <Body isSelaProduct={isSelaProduct}>
            <HeaderBox
              isHsInstancedView={isHsInstancedView}
              isSelaProduct={isSelaProduct}
            >
              {isHighSchool && (
                <>
                  <HighSchoolProgramHeader key="header-welcome-displaytitle">
                    {superscriptRegistered(displayTitle)}
                  </HighSchoolProgramHeader>
                  <TileContainer>
                    <SiteSelector />
                    {isSetup && hasUpdatedProfile && <ProgramRoleTile />}
                  </TileContainer>
                </>
              )}
              {!isHighSchool && (
                <HeaderTwo
                  dataTestId={headerTestId}
                  key="header-welcome-displaytitle"
                >
                  <ProgramTitle
                    displayTitle={displayTitle}
                    displayTitleLink={displayTitleLink}
                    isHighSchool={isHighSchool}
                    isSelaProduct={isSelaProduct}
                    onComponentClick={onComponentClick}
                  />
                </HeaderTwo>
              )}
              {(isK5Product || isMsProduct) && !firstTime && (
                <CreateCourse
                  classesUrl={classesUrl}
                  contentfulCourses={contentfulCourses}
                  ref={ref}
                />
              )}
            </HeaderBox>
            <Content isHighSchool={isHighSchool}>{children}</Content>
          </Body>
        </Background>
      </ThemeProvider>
    )
  },
)

ProgramCard.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  classesUrl: PropTypes.string,
  contentfulCourses: PropTypes.array,
  currentSite: PropTypes.object,
  displayTitle: PropTypes.string,
  displayTitleLink: PropTypes.string,
  firstTime: PropTypes.bool,
  isHighSchool: PropTypes.bool,
  isHighSchoolFamily: PropTypes.bool,
  isHsInstancedView: PropTypes.bool,
  isK5Product: PropTypes.bool,
  isMindYetiProduct: PropTypes.bool,
  isMsProduct: PropTypes.bool,
  isOstProduct: PropTypes.bool,
  isSelaProduct: PropTypes.bool,
  onComponentClick: PropTypes.func.isRequired,
  program: PropTypes.string,
  theme: PropTypes.object,
}

export default ProgramCard
