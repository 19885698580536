import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Box } from 'grommet'
import { HeaderThree } from 'secondstep-components'

export const TilesContainer = styled(Box)`
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1.25rem;
  padding: 0.625rem 0.625rem 1.25rem 0.625rem;
  min-width: 18.4375rem;

  ${HeaderThree} {
    width: 100%;
  }

  ${themeGet('breakpoints.xlarge')} {
    min-width: 39.375rem;
  }
  ${themeGet('breakpoints.tablet')} {
    min-width: 0;
  }
`
export const OstTeachLinksWrapper = styled.div`
  button {
    background-color: ${themeGet('colors.buttons.primaryBackground')};
    &:hover {
      background-color: ${themeGet('colors.buttons.primaryBackgroundHover')};
    }
  }
  .title {
    font-family: ${themeGet('font.molde.semiBold')};
    color: white;
    letter-spacing: 0;
    line-height: 18px;
    font-weight: 400;
  }
  .label {
    color: white;
  }
`

export const OstStaticLinksWrapper = styled.div`
  border-radius: 0.625rem;
  .title {
    font-family: ${themeGet('font.molde.semiBold')};
    font-weight: 400;
    color: ${themeGet('colors.buttons.header.level.5')};
    letter-spacing: 0;
    line-height: 18px;
  }
  svg {
    path {
      fill: ${themeGet('colors.labels.primaryBackground')};
    }
  }
  &:hover {
    .label {
      color: ${themeGet('colors.labels.primaryHoverBackground')};
    }
    svg {
      path {
        fill: ${themeGet('colors.labels.primaryHoverBackground')};
      }
    }
  }
  .label {
    color: ${themeGet('colors.labels.primaryBackground')};
  }
`
