import React, { forwardRef, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Grommet, Select } from 'grommet'
import { HeaderOne, TabButton, TabSet, CheckIcon } from 'secondstep-components'
import { toUrlSafe } from 'utils/stringHelpers'
import {
  DropdownPrograms,
  HeadingWrapper,
  ProgramOption,
  StyledDropdown,
  TabSetWrapper,
  tabTheme,
} from './component.styles'
import { PAGE_TITLE } from './constants'

const HeaderTabs = forwardRef(
  (
    {
      dataTestId,
      defaultActiveTab,
      id,
      onSelect,
      pageTitle,
      programGroup,
      routedTab,
      shouldRenderSelectDropdown,
    },
    ref,
  ) => {
    const [value, setValue] = useState(defaultActiveTab?.displayTitle)
    const selectRef = useRef()
    useEffect(() => {
      if (value !== defaultActiveTab?.displayTitle) {
        // Somehow in line 24 doesn't assign the default value in the first load
        setValue(defaultActiveTab.displayTitle)
      }
    }, [defaultActiveTab?.displayTitle])

    const filteredProgramGroup = programGroup.filter(p => !!p.route)

    const programs = filteredProgramGroup.map(program => ({
      value: program.route,
      label: program.displayTitle,
    }))

    return (
      <HeadingWrapper dataTestId={dataTestId} id={id}>
        <HeaderOne dataTestId="page-title-dashboard">
          {pageTitle || PAGE_TITLE}
        </HeaderOne>
        <Grommet theme={tabTheme}>
          {filteredProgramGroup?.length > 1 && (
            <TabSetWrapper>
              {!shouldRenderSelectDropdown && (
                <TabSet dataTestId="tabset-programs" ref={ref}>
                  {filteredProgramGroup.map(program => {
                    const tabSelected = !!routedTab
                    const programRouteMatchesDefault =
                      program.route === defaultActiveTab.route
                    const routedTabMatchesProgramRouteUrl =
                      toUrlSafe(program.route) === routedTab

                    const isActive = tabSelected
                      ? routedTabMatchesProgramRouteUrl
                      : programRouteMatchesDefault

                    return (
                      <TabButton
                        dataTestId={`tab-button-${toUrlSafe(
                          program.displayTitle,
                        )}`}
                        isActive={isActive}
                        key={program.id}
                        label={program.displayTitle}
                        onClick={() => onSelect(program.route)}
                      />
                    )
                  })}
                </TabSet>
              )}
              {shouldRenderSelectDropdown && (
                <StyledDropdown>
                  <DropdownPrograms>
                    <Select
                      dropProps={{
                        round: {
                          size: '5px',
                        },
                        align: { top: 'bottom', left: 'left' },
                        margin: { vertical: '6px' },
                        target: selectRef.current,
                      }}
                      onChange={({ option }) => {
                        const selectedRoute = programs.find(
                          ({ label }) => label === option,
                        )
                        onSelect(selectedRoute.value)
                        setValue(option)
                      }}
                      options={programs.map(({ label }) => label)}
                      ref={selectRef}
                      value={value}
                    >
                      {(option, _index, _options, { selected }) => {
                        return (
                          <ProgramOption>
                            {option} {selected && <CheckIcon />}
                          </ProgramOption>
                        )
                      }}
                    </Select>
                  </DropdownPrograms>
                </StyledDropdown>
              )}
            </TabSetWrapper>
          )}
        </Grommet>
      </HeadingWrapper>
    )
  },
)

HeaderTabs.propTypes = {
  dataTestId: PropTypes.string.isRequired,
  defaultActiveTab: PropTypes.object,
  id: PropTypes.string,
  onSelect: PropTypes.func,
  pageTitle: PropTypes.string,
  programGroup: PropTypes.array,
  routedTab: PropTypes.string,
  shouldRenderSelectDropdown: PropTypes.bool,
}

export default HeaderTabs
